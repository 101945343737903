// eslint-disable-next-line no-unused-vars
globalThis.MyDentalBooking = (() => {
  function initDiary(customerReference, autoHeight = true, navbarId, scrollOffsetTop = 0) {
    const baseHost = "mydentalbooking.co.uk";
    const diaryOrigin = `https://${customerReference}.${baseHost}`;
    let frameSrc = `${diaryOrigin}/embed/Book?HideNav=true&HideLogo=true`;
    let navbarElement;

    if (autoHeight) {
      frameSrc += "&DisableScrolling=true";
    }

    // Load the frame
    const diaryFrame = document.querySelector("iframe#mdb-diary");
    diaryFrame.src = frameSrc;

    function handleWindowMessages() {
      if (navbarId) {
        navbarElement = document.getElementById(navbarId);
      }

      window.addEventListener("message", (e) => {
        // Ignore untrusted window messages
        // Allowed origins:
        //  - https://mydentalbooking.co.uk
        //  - https://*.mydentalbooking.co.uk

        // If not the base origin and not a subdomain of the base origin, ignore the message
        if (e.origin !== `https://${baseHost}`
          && !(e.origin.startsWith("https://") && e.origin.endsWith(`.${baseHost}`))) {
          return;
        }

        if (autoHeight) {
          if ("height" in e.data) {
            diaryFrame.height = e.data.height;
          }

          if ("scrollTo" in e.data) {
            const rect = diaryFrame.getBoundingClientRect();
            const win = diaryFrame.ownerDocument.defaultView;
            let top = rect.top + win.scrollY + e.data.scrollTo - scrollOffsetTop;

            // Account for a "fixed"/"sticky" position navbar
            if (navbarElement) {
              const pos = getComputedStyle(navbarElement).position;
              if (pos == "fixed" || pos == "sticky") {
                top -= navbarElement.offsetHeight;
              }
            }

            window.scroll({ top, behavior: "smooth" });
          }
        }

        if ("redirect" in e.data) {
          const returnUrl = encodeURIComponent(window.location.href);
          window.location.href = `${e.data.redirect}?ReturnUrl=${returnUrl}`;
        }
      });
    }

    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", () => {
        handleWindowMessages();
      });
    } else {
      handleWindowMessages();
    }
  }

  return {
    initDiary,
  };
})();
